import {ApolloClient, InMemoryCache} from '@apollo/client';

import {productFiltersToPackageFilters} from '../advisor-config/package/smartphones';
import {GetFeaturedDealsDocument, GetProductDocument, ProductFilters} from '../generated/graphql';
import {env} from '../config';

export const client = new ApolloClient({
  uri: `${env.NEXT_PUBLIC_APP_URL}/graphql`,
  cache: new InMemoryCache(),
  ssrMode: typeof window !== undefined,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'none',
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'none',
    },
  },
});

export function getFacets(queryDocument: any, platformName: string) {
  return client.query({
    query: queryDocument,
    variables: {
      platformName,
    },
  });
}

export function getOffers(queryDocument: any, platformName: string, filters: ProductFilters) {
  return client.query({
    query: queryDocument,
    variables: {
      platformName,
      filters,
      first: 10,
      packageFilters: productFiltersToPackageFilters(filters),
    },
  });
}

export function getProduct(productId: number, platformName: string) {
  return client.query({
    query: GetProductDocument,
    variables: {productId, platformName},
  });
}

export function getFeaturedDeals(platformId: number, offerType: string, productId?: number, category?: string) {
  return client.query({
    query: GetFeaturedDealsDocument,
    fetchPolicy: 'no-cache',
    variables: {platform: platformId, offerType, productId, category},
  });
}
