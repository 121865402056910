import {PackageFacets, PlanFacets, ProductFacets, SmartphonesProductVersionFacets} from '../generated/graphql';
import {SortOrder, SortByField} from '../utils/sorting';

export const EMPTY_DEFAULT_IDENTIFIER = 'EMPTY_DEFAULT';

export enum ControlType {
  Checkbox = 'checkbox',
  Radio = 'radio',
  Text = 'text',
  Slider = 'slider'
}

export enum FilterType {
  Checkbox = 'checkbox',
  Badge = 'badge',
  Range = 'range',
  Text = 'text'
}

export enum SearchType {
  In = 'In',
  Range = 'Range',
  RangeMin = 'RangeMin',
  RangeMax = 'RangeMax',
}

interface CreateFilterGroupInput {
  label?: string;
  filters: BaseFilter[];
}

export interface FilterGroup extends CreateFilterGroupInput {
  hasFeaturedFilters: (filters: BaseFilter[]) => boolean;
  hasNonFeaturedFilters: (filters: BaseFilter[]) => boolean;
}

export const createFilterGroup = ({
  label,
  filters,
}: CreateFilterGroupInput): FilterGroup => ({
  label,
  filters,
  hasFeaturedFilters: (filters) => filters.some((filter) => filter.featured),
  hasNonFeaturedFilters: (filters) => filters.some((filter) => !filter.featured && filter.options && filter.options.length > 1),
});

interface CreateAdvisorConfigInput {
  category: string;
  filterGroups: FilterGroup[];
  featuredSpecs?: ProductSpec[];
  subSpecs?: ProductSpec[];
}

export interface AdvisorConfig extends CreateAdvisorConfigInput {
  allFilters: BaseFilter[];
}

export const createAdvisorConfig = ({
  category,
  filterGroups,
  featuredSpecs,
  subSpecs,
}: CreateAdvisorConfigInput): AdvisorConfig => ({
  category,
  filterGroups,
  allFilters: filterGroups.flatMap((group) => group.filters),
  featuredSpecs,
  subSpecs,
});

export interface ProductSpec {
  key: string;
  formatter?: (value: string) => string;
  pluralSuffix: string;
  singularSuffix: string;
  showFirst: boolean;
}

export interface FilterOption {
  label: string;
  value: number | string | boolean;
  secondaryValue?: number | string;
  url?: string;
}

export interface OptionProps {
  option: FilterOption;
  compact: boolean;
}

export interface UnitFormatter {
  unit: string;
  formatter: (value: number) => string;
}

interface CreateFilterInput {
  identifier: string;
  label: string;
  controlType: ControlType;
  Description?: React.FunctionComponent | string;
  searchType?: SearchType;
  filterType?: FilterType;
  append?: string;
  Option?: React.FunctionComponent<OptionProps>;
  gridCols: number;
  unitFormatter?: UnitFormatter;
  minOptions?: number;
  onlyShowFilterIfHasTerms?: boolean;
  withoutOptions?: boolean;
  options?: FilterOption[];
  featured?: boolean;
  initialSortBy?: SortByField;
  featuredSortBy?: SortByField;
  nonFeaturedSortBy?: SortByField;
  compact?: boolean;
  showAllThreshHold?: number;
}

export interface BaseFilter extends CreateFilterInput {
  nullableOptions?: boolean;
  gridCols: number;
  minOptions: number;
  onlyShowFilterIfHasTerms: boolean;
  withoutOptions: boolean;
  initialOptions: FilterOption[];
  featured?: boolean;
  initialSortBy?: SortByField;
  featuredSortBy?: SortByField;
  nonFeaturedSortBy?: SortByField;
  showAllThreshHold: number;
}

export interface BaseFilterProps {
  filter: BaseFilter;
  handleOnChange: (filter: BaseFilter, option: FilterOption) => void;
  selectedOptions: any;
  compact?: boolean;
}

export const createFilter = ({
  identifier,
  label,
  controlType,
  Description,
  searchType = SearchType.In,
  filterType = FilterType.Checkbox,
  unitFormatter,
  append = '',
  Option,
  gridCols,
  minOptions = 2,
  options = undefined,
  onlyShowFilterIfHasTerms = false,
  withoutOptions = false,
  featured,
  initialSortBy = {field: 'count', order: SortOrder.ASC},
  featuredSortBy = {field: 'value', order: SortOrder.ASC},
  nonFeaturedSortBy = {field: 'value', order: SortOrder.ASC},
  compact,
  showAllThreshHold = 7,
}: CreateFilterInput): BaseFilter => ({
  identifier,
  label,
  controlType: featured ? ControlType.Radio : controlType,
  Description,
  searchType,
  filterType,
  unitFormatter,
  Option,
  append,
  gridCols,
  minOptions,
  options,
  initialOptions: [],
  onlyShowFilterIfHasTerms,
  withoutOptions,
  nullableOptions: Boolean(featured),
  featured,
  initialSortBy,
  featuredSortBy,
  nonFeaturedSortBy,
  compact,
  showAllThreshHold,
});

type Facets = ProductFacets | PlanFacets | PackageFacets | SmartphonesProductVersionFacets;

export const facetsToFilters = (config: AdvisorConfig, facets: Facets) => {
  return config.filterGroups
    .map((group) => {
      const groupFilters: BaseFilter[] = group.filters.map((filter) => {
        if (filter.withoutOptions || (filter.options && !filter.onlyShowFilterIfHasTerms)) {
          return filter;
        }

        const options = (facets as any[])[`${filter.identifier}Terms` as any] || [];

        if (filter.onlyShowFilterIfHasTerms) {
          return options.length > 1 ? filter : {...filter, options: []};
        }

        return {
          ...filter,
          options: options
            .map((option: any) => ({
              ...option,
              label: createFilterOptionLabel(option.value, filter.append as string),
            })),
        };
      }).filter((filter) => filter.withoutOptions || filter.options?.length > 0);

      return {
        ...group,
        filters: groupFilters,
      };
    });
};

export const filterGridColsClass = (gridCols: number) => ({
  /* eslint-disable @typescript-eslint/naming-convention */
  1: 'sf-grid-cols-1',
  2: 'sf-grid-cols-2',
  3: 'sf-grid-cols-3',
  /* eslint-enable @typescript-eslint/naming-convention */
}[gridCols]);

const createFilterOptionLabel = (value: string | number, append: string) => `${value}${append || ''}`;

export const formatValueAsCurrency = (value: number) => {
  return Intl.NumberFormat('nl-NL', {
    style: 'currency',
    currency: 'EUR',
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  }).format(value);
};
