import {gql} from '@apollo/client';

import {ProductFilters} from '../../generated/graphql';
import {
  createBKRFilter,
  createCallLimitFilter,
  createColorTermsFilter,
  createContractDurationFilter,
  createDataLimitFilter,
  createDeliveryTimeRangeTermsFilter,
  createMemoryTermsFilter,
  createPackageDiscountFilter,
  createPlanNetworkGenerationFilter,
  createPlanPriceFilter,
  createPlanRenewalFilter,
  createProductPriceFilter,
  createTelcoFilter,
  createDownloadSpeedFilter,
} from '../sharedFilters';
import {AdvisorConfig, createAdvisorConfig, createFilterGroup, SearchType} from '../utils';

export const smartphonesConfig: AdvisorConfig = createAdvisorConfig({
  category: 'Smartphones',
  filterGroups: [
    createFilterGroup(
      {
        filters: [
          createMemoryTermsFilter({featured: true}),
          createColorTermsFilter({featured: true}),
          createPlanPriceFilter(),
          createProductPriceFilter(),
          createBKRFilter(),
          createDataLimitFilter(),
          createCallLimitFilter(),
          createPackageDiscountFilter(),
          createTelcoFilter(),
          createPlanRenewalFilter(),
          createPlanNetworkGenerationFilter(),
          createContractDurationFilter(),
          createDeliveryTimeRangeTermsFilter(),
          createDownloadSpeedFilter(),
        ],
      },
    ),
  ],
});

export const productFiltersToPackageFilters = (filters: ProductFilters) => {
  const whiteListedFilters = ['productNameIn', 'categoryNameIn', 'brandNameIn'];
  const packageFilters = [...smartphonesConfig.allFilters.map((filter) => `${filter.identifier}${filter.searchType === SearchType.In ? 'In' : 'Range'}`), ...whiteListedFilters];
  return Object.fromEntries(Object.entries(filters).filter(([key]) => packageFilters.includes(key)));
};

export const {definitions} = gql`
  fragment smartphonePackageFacets on PackageFacets {
    memoryTerms {
      ...integerFilter
    }
    colorNameTerms {
      ...stringFilter
      secondaryValue
    }
    withoutBkrTerms {
      ...booleanFilter
    }
    deliveryTimeRangeTerms {
      ...integerFilter
    }
    telcoNameTerms {
      ...stringFilter
    }
    planContractDurationTerms {
      ...integerFilter
    }
    planRenewalTerms {
      ...booleanFilter
    }
    planNetworkGenerationTerms {
      ...stringFilter
    }
    packageDiscountNameTerms {
      ...stringFilter
    }
  }
`;
